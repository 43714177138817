@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  direction: rtl
} */

html body {
  margin: 0;
}